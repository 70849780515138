import { ISpaceInfo } from '@trii/types/dist/Spaces';

const fetchSpacesInfo = async (jwt: string): Promise<ISpaceInfo[]> => {
  const headers = { Authorization: `Bearer ${jwt}` };

  const response = await fetch('https://account.trii.app/api/v1/MySpaces', {
    headers,
  });

  if (!response.ok) {
    throw new Error('Error al obtener la información de los espacios');
  }

  const data: ISpaceInfo[] = await response.json();
  return data;
};

const spaceService = {
  fetchSpacesInfo,
};
export default spaceService;
