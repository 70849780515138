// Service
import deviceService from '../service/deviceService';
import firebaseHelper from './firebaseHelper';

async function saveDevice(jwt: string) {
  const token = await firebaseHelper.getFirebaseToken();

  await deviceService.add(token, jwt);
}

async function getPermission() {
  if (!('Notification' in window)) {
    return false;
  }

  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    return true;
  } else if (permission === 'denied') {
    console.log('You denied the notification');
    return false;
  }
}

export default {
  getPermission,
  saveDevice,
};
