// Spa
import { registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes } from 'single-spa-layout';
// Utils
import moduleHelper from './utils/moduleHelper';
import spinnerHelper from './utils/spinnerHelper';
import api from './utils/api';
import notificationHelper from './utils/notificationHelper';
import themeHelper from './utils/themeHelper';
import Push from 'push.js';
import firebaseHelper from './utils/firebaseHelper';

const pathSegments = window.location.pathname.split('/');

const firstSegment = pathSegments[0] === '' ? pathSegments[1] : pathSegments[0];

const elementId =
  firstSegment === 'view' ? '#single-spa-layout-view' : '#single-spa-layout-a';
// Routes
const routes = constructRoutes(document.querySelector(elementId));

navigator.serviceWorker.addEventListener('message', (event) => {
  console.log('Received message from service worker:', event.data);
  const eventName = event.data.eventKey;
  const moduleName = moduleHelper.getModuleNameForEvent(eventName);

  if (
    !moduleHelper.doesUrlContainModule(eventName) &&
    document.visibilityState !== 'visible'
  ) {
    localStorage.setItem(`newevents_${moduleName}`, 'true');
  } else {
    setTimeout(() => {
      localStorage.setItem(`newevents_${moduleName}`, 'false');
    }, 500);
  }

  // Push.create(event.data.notification.title, {
  //   body: event.data.notification.body,
  //   icon: 'https://agent.trii.app/favicon/Trii.svg',
  //   timeout: 4000,
  //   onClick: function () {
  //     window.focus();
  //     this.close();
  //   },
  // });
});

const applications = constructApplications({
  routes,
  //@ts-ignore
  loadApp({ name }) {
    return Promise.resolve()
      .then(() => {
        spinnerHelper.show();
        return System.import(name);
      })
      .then((app) => {
        spinnerHelper.hide();
        return app;
      });
  },
});

const setApplications = async () => {
  try {
    const { pool, userInfo, spaceInfo, jwt } = await api();
    console.log('A');
    if (userInfo) themeHelper.setTheme(userInfo.theme);
    console.log('B');

    const notificationPermission = await notificationHelper.getPermission();
    console.log('C');

    if (notificationPermission) {
      await notificationHelper.saveDevice(jwt);
    }
    console.log('D');

    const firebaseToken = (await firebaseHelper.getFirebaseToken()) || '';
    console.log('E');

    applications.forEach((app) => {
      console.log('App: ', app);

      app.customProps = {
        spaceInfo,
        userInfo,
        pool,
        firebaseToken,
      };

      registerApplication(app);
    });
    console.log('F');

    spinnerHelper.hide();
  } catch (e) {
    console.error(e);
    window.location.href = `https://account.trii.app/signin/?redirect=${window.location.href}`;
  }
};

setApplications();
start();
