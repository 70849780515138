import { ISpaceInfo } from '@trii/types/dist/Spaces';

const setLocalStorageSpaceInfo = (spaceInfo: ISpaceInfo) => {
  localStorage.setItem('spaceInfo', JSON.stringify(spaceInfo));
};

const getLocalStorageSpaceInfo = (): ISpaceInfo => {
  const spaceInfo = localStorage.getItem('spaceInfo');
  const spaceInfoJson: ISpaceInfo = JSON.parse(spaceInfo);

  return spaceInfoJson;
};

// This method sets a valid space in localStorage and returns it
const initSpaceInfo = (spacesInfo: ISpaceInfo[]): ISpaceInfo => {
  if (!spacesInfo.length) {
    // If there are no spaces then it redirects to the no spaces page
    window.location.pathname = '/message/nospace';
    return;
  }

  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);

  var params = new URLSearchParams(urlObject.search);

  const spaceIdParam = params.get('spaceId');

  if (spaceIdParam) {
    const spaceFound = spacesInfo.find((space) => space.id === spaceIdParam); // Searchs for the space in the spacesInfo array,
    params.delete('spaceId'); // Erases the spaceId param from the url as it is not needed anymore
    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    window.history.replaceState({}, document.title, baseUrl); // Replaces the current URL in the browser's history stack without refreshing it

    // If its a valid space then it sets it in localStorage and returns it
    if (spaceFound) {
      setLocalStorageSpaceInfo(spaceFound);
      return spaceFound;
    }
  }

  let localStorageSavedSpace = getLocalStorageSpaceInfo();

  if (localStorageSavedSpace) {
    // If there is a space saved in localStorage then it checks if it is a valid space
    const spaceFound = spacesInfo.find(
      (space) => space.id === localStorageSavedSpace.id
    );
    // If the space saved in localStorage is not a valid space then it sets the last space in the spacesInfo array
    if (!spaceFound) localStorageSavedSpace = spacesInfo[spacesInfo.length - 1];
  } else {
    // If there is no space saved in localStorage then it sets the last space in the spacesInfo array
    localStorageSavedSpace = spacesInfo[spacesInfo.length - 1];
  }

  setLocalStorageSpaceInfo(localStorageSavedSpace); // Sets the space in localStorage before returning it
  return localStorageSavedSpace;
};

const spaceHelper = {
  initSpaceInfo,
};

export default spaceHelper;
