import { IDevice } from '@trii/types/dist/Accounts';

const URL = 'https://account.trii.app/api/v1/Devices';

const add = async (deviceToken: string, jwt: string): Promise<IDevice> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${jwt}`,
  };

  const response = await fetch(URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({ token: deviceToken }),
  });

  if (response.ok) {
    const data: IDevice = await response.json();
    return data;
  }
};

export default { add };
