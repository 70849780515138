import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAGojl_rXolSl2Xbwqc8TVhBPIi6UKEpjw",
  authDomain: "trii-app-c9b35.firebaseapp.com",
  projectId: "trii-app-c9b35",
  storageBucket: "trii-app-c9b35.appspot.com",
  messagingSenderId: "243763149644",
  appId: "1:243763149644:web:0b42c22f1f3171589cede9",
  measurementId: "G-ZRTSZ5Q6J3"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
