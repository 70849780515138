import { CognitoUserSession } from 'amazon-cognito-identity-js';
import pool from '../pool';
import sessionHelper from '../utils/sessionHelper';
import { deleteCognitoCookiesWithPrefix } from '../utils/cookieHelper';

const getSession = async (): Promise<CognitoUserSession> => {
  return new Promise(async (resolve, reject) => {
    const user = pool.getCurrentUser();
    // user.signOut
    if (user) {
      user.getSession(async (err, session: CognitoUserSession) => {
        if (err || !session) {
          console.log('user.signOut');
          await user.signOut();
          //deleteCognitoCookiesWithPrefix('CognitoIdentityServiceProvider.');

          reject(err);
        } else {
          resolve(sessionHelper.checkSessionValidity(user, session));
        }
      });
    } else {
      console.log('user.signOut error');
      // await user.signOut();
      //deleteCognitoCookiesWithPrefix('CognitoIdentityServiceProvider.');

      reject('Get session rejected: No user');
    }
  });
};

const sessionService = { getSession };

export default sessionService;
