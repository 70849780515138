// Service
import sessionService from '../service/sessionService';
import spaceService from '../service/spaceService';
import userService from '../service/userService';
// Aws
import pool from '../pool';
import spaceHelper from './spaceHelper';

const api = async () => {
  try {
    const session = await sessionService.getSession();
    const jwt = session.getIdToken().getJwtToken(); // Aca se produce error cuando el token expira
    const spacesInfo = await spaceService.fetchSpacesInfo(jwt);

    const localStorageSavedSpace = spaceHelper.initSpaceInfo(spacesInfo); // This method sets a valid space in localStorage and returns it

    let spaceId = '';

    if (!localStorageSavedSpace) {
      spaceId = spacesInfo[spacesInfo.length - 1].id;
    } else {
      spaceId = localStorageSavedSpace.id;
    }

    const userInfo = await userService.fetchUserInfo(jwt, spaceId);
    return {
      spaceInfo: localStorageSavedSpace,
      userInfo,
      pool,
      jwt,
    };
  } catch (e) {
    console.error(e);
    // console.log('Now should redirect to : ', 'https://account.trii.app/signin');
    // window.location.href = 'https://account.trii.app/signin';
  }
};

export default api;
