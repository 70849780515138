interface Theme {
  [key: string]: string;
}
const themeList: Theme = {
  lightBlueIndigo: "#ebf6fd",
  lightBlue: "#e3f2fd",
  ligthRed: "#ffebee",
  lightGreen: "#e8f5e9",
  lightPink: "#fce4ec",
  lightPurple: "#f3e5f5",
  lightTeal: "#e0f2f1",
  lightLime: "#f9fbe7",
  lightYellow: "#fffde7",
  lightAmber: "#fff8e1",
  lightOrange: "#fff3e0",
  lightBrown: "#efebe9",
  lightGray: "#fafafa",
  darkBlue: "#28324e",
  darkBlack: "#252423",
};

export default themeList;