// Types
import { UserTrii } from '@trii/types/dist/Users';

const fetchUserInfo = async (jwt: string, spaceId: string): Promise<UserTrii> => {
  const headers = { Authorization: `Bearer ${jwt}` };
  const response = await fetch(
    `https://agent-api.trii.app/api/v1/settings/${spaceId}/user`,
    {
      headers,
    }
  );

  if (!response.ok) {
    throw new Error('Error al obtener la información del usuario');
  }

  const data: UserTrii = await response.json();
  return data;
};

const userService = { fetchUserInfo };

export default userService;
