function getModuleNameForEvent(eventName: string): string | null {
  const [prefix] = eventName.split('_');

  if (prefix === 'conversation') {
    return 'conversations';
  } else if (prefix === 'chat') {
    return 'chat';
  }

  return null;
}
function doesUrlContainModule(eventName: string): boolean {
  const moduleName = getModuleNameForEvent(eventName);

  if (!moduleName) {
    return false;
  }

  // Ensure the module name is in the correct part of the URL (after "/a/")
  const moduleInUrl = location.pathname.split('/a/')[1];
  return moduleInUrl?.startsWith(moduleName) ?? false;
}

export default {
  getModuleNameForEvent,
  doesUrlContainModule,
};
